import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  getOrganizationChart (courseName, disciplineName, page, rowsPerPage) {
    return custAxios().get(`${Const.API_ORGANIZATION_CHART}?searchCourse=${btoa(courseName)}&searchDiscipline=${btoa(disciplineName)}&page=${page}&rowsPerPage=${rowsPerPage}`)
  },
  getOrganizationChartExport (courseName, disciplineName) {
    return custAxios().get(`${Const.API_ORGANIZATION_CHART}/export?searchCourse=${btoa(courseName)}&searchDiscipline=${btoa(disciplineName)}`)
  }
}
