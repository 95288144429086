import disciplinesApi from '@/api/disciplines'

export const SET_SEARCH_DISCIPLINE = 'SET_SEARCH_DISCIPLINE'
export const SET_CHANGE_TOTAL_LIMIT = 'SET_CHANGE_TOTAL_LIMIT'

const state = {
  searchDiscipline: '',
  changeTotalLimit: null
}

const getters = {
  getterSearchDiscipline: (state) => state.searchDiscipline,
  getterChangeTotalLimit: (state) => state.changeTotalLimit
}

const mutations = {
  [SET_SEARCH_DISCIPLINE] (state, value) {
    state.searchDiscipline = value
  },
  [SET_CHANGE_TOTAL_LIMIT] (state, value) {
    state.changeTotalLimit = value
  }
}

const actions = {
  actionGetDisciplines (_, params) {
    return disciplinesApi.getDisciplines(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionGetUasByDisciplineId (_, id) {
    return disciplinesApi.getUasByDisciplineId(id)
      .then((response) => {
        return Promise.resolve(response.data)
      }).catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionDeleteUaOfDiscipline (_, params) {
    return disciplinesApi.deleteUaOfDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      }).catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionInsertDiscipline (_, params) {
    return disciplinesApi.insertDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionUpdateDiscipline (_, params) {
    return disciplinesApi.updateDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionDeleteDiscipline (_, id) {
    return disciplinesApi.deleteDiscipline(id)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionGetDisciplinesUnapproved (_, params) {
    return disciplinesApi.getUnapprovedDisciplines(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionGetApprovedDisciplines (_, params) {
    return disciplinesApi.getApprovedDisciplines(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionPostUaInDiscipline (_, params) {
    return disciplinesApi.postUaInDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data)
      })
  },
  actionGetByUa (_, params) {
    return disciplinesApi.getByUa(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionAskCreateDiscipline (_, params) {
    return disciplinesApi.postAskCreateDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionOrderUasOfDisciplines (_, params) {
    return disciplinesApi.postOrderUasOfDisciplines(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionUpdateUasLimitOfDisciplines (_, params) {
    return disciplinesApi.updateUasLimitOfDisciplines(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionUpdateUasLimitOfAllDisciplines (_, params) {
    return disciplinesApi.updateUasLimitOfAllDisciplines(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionDeleteAskExclusionDiscipline (_, params) {
    return disciplinesApi.deleteAskExclusionDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionPutReopenDiscipline (_, params) {
    return disciplinesApi.putReopenDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionPutDuplicateDiscipline (_, params) {
    return disciplinesApi.putDuplicateDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data)
      })
  },
  actionPutApproveDiscipline (_, params) {
    return disciplinesApi.putApproveDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data)
      })
  },
  actionPutAskApproveDiscipline (_, params) {
    return disciplinesApi.putAskApproveDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionPutDisapproveDiscipline (_, params) {
    return disciplinesApi.putDisapproveDiscipline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
