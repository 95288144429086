import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  getUas (params) {
    return custAxios().post(`${Const.API_UA}/byAreaAndSubArea?sortBy=${params.sortBy}&page=${params.page}&rowsPerPage=${params.rowsPerPage}`, params.data)
  },
  getCountUas () {
    return custAxios().get(`${Const.API_UA}/count`)
  },
  getUasByName (params) {
    return custAxios().get(`${Const.API_UA}/byUaName?search=${btoa(params.search)}&sortBy=${params.sortBy}&page=${params.page}&rowsPerPage=${params.rowsPerPage}`)
  },
  generateUrlUaSagah (params) {
    return custAxios().post(`${Const.API_URL}/tokenUaSagah`, params)
  },
  generateUrlUaTeacher (params) {
    return custAxios().post(`${Const.API_URL}/tokenUaTeacher`, params)
  },
  getUnits (params) {
    return custAxios().get(`${Const.API_UA}?search=${btoa(params.search)}&page=${params.page}&rowsPerPage=20`)
  },
  redirectToEditInSagahCM (params) {
    return custAxios().post(`${Const.API_SAGAHTEACHERTOKEN}`, params)
  },
  deleteUnits (params) {
    return custAxios().delete(`${Const.API_UA}/${params.id}`, {
      data:
        {
          version: params.version
        }
    })
  },
  verifyDynamicUA () {
    return custAxios().get(`${Const.API_DYNAMIC_UA}`)
  },
  redirectToSagahCm (params) {
    return custAxios().post(`${Const.API_SAGAHTOKEN}`, params)
  },
  redirectTeacherToSagahCm (params) {
    return custAxios().post(`${Const.API_SAGAHTEACHERTOKEN}`, params)
  }
}
