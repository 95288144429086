import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  getCourses (params) {
    return custAxios().get(`${Const.API_COURSES}?search=${btoa(params.search)}&sortDirection=${params.sort}`)
  },
  insertCourse (name) {
    return custAxios().post(`${Const.API_COURSES}`, { name })
  },
  updateCourse (params) {
    return custAxios().put(`${Const.API_COURSES}/${params.id}`, { name: params.name })
  },
  deleteCourse (id) {
    return custAxios().delete(`${Const.API_COURSES}/${id}`)
  }
}
