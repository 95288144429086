import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  getUasByDisciplineId (id) {
    return custAxios().get(`${Const.API_DISCIPLINES}/${id}/ua`)
  },
  deleteUaOfDiscipline (params) {
    return custAxios().delete(`${Const.API_DISCIPLINES}/${params.idDiscipline}/ua/${params.disciplineuaid}`)
  },
  insertDiscipline (params) {
    return custAxios().post(`${Const.API_DISCIPLINES}`, {
      name: params.name,
      courseId: params.courseid
    })
  },
  updateDiscipline (params) {
    return custAxios().put(`${Const.API_DISCIPLINES}/${params.disciplineid}`, {
      name: params.name,
      courseId: params.courseid
    })
  },
  deleteDiscipline (id) {
    return custAxios().delete(`${Const.API_DISCIPLINES}/${id}`)
  },
  getUnapprovedDisciplines (params) {
    return custAxios().get(`${Const.API_DISCIPLINES}/unapproved?search=${btoa(params.search)}&sortDirection=${params.sortDirection}&page=${params.page}&rowsPerPage=${params.rowsPerPage}`)
  },
  getApprovedDisciplines (params) {
    return custAxios().get(`${Const.API_DISCIPLINES}/approved?search=${btoa(params.search)}&sortDirection=${params.sortDirection}&page=${params.page}&rowsPerPage=${params.rowsPerPage}`)
  },
  postUaInDiscipline (params) {
    return custAxios().post(`${Const.API_DISCIPLINES}/${params.disciplineId}/ua`, params.data)
  },
  getByUa (params) {
    return custAxios().get(`${Const.API_DISCIPLINES}/ua/${params.uaId}?search=${params.search}&sortDirection=${params.sortDirection}&sortBy=${params.sortBy}&page=${params.page}&rowsPerPage=${params.rowsPerPage}`)
  },
  postAskCreateDiscipline (params) {
    return custAxios().post(`${Const.API_DISCIPLINES}/askCreate`, params)
  },
  postOrderUasOfDisciplines (params) {
    return custAxios().post(`${Const.API_DISCIPLINES}/${params.disciplineid}/ua/position`, {
      uas: params.uas
    })
  },
  getDisciplines (params) {
    return custAxios().get(`${Const.API_DISCIPLINES}?search=${btoa(params.search)}&sortBy=${params.sortBy}&page=${params.page}&rowsPerPage=${params.rowsPerPage}&sortDirection=${params.sortDirection}`)
  },
  updateUasLimitOfDisciplines (params) {
    return custAxios().put(`${Const.API_DISCIPLINES}/defaultUas/${params.id}`, {
      defaultUas: params.total
    })
  },
  updateUasLimitOfAllDisciplines (params) {
    return custAxios().put(`${Const.API_DISCIPLINES}/defaultUas/all`, {
      defaultUas: params
    })
  },
  deleteAskExclusionDiscipline (params) {
    return custAxios().put(`${Const.API_DISCIPLINES}/askExclusion/${params.disciplineId}`)
  },
  putReopenDiscipline (params) {
    return custAxios().put(`${Const.API_DISCIPLINES}/reopen/${params.disciplineId}`)
  },
  putDuplicateDiscipline (params) {
    return custAxios().put(`${Const.API_DISCIPLINES}/duplicate/${params.disciplineId}`, {
      disciplineNames: params.disciplineNames
    })
  },
  putApproveDiscipline (params) {
    return custAxios().put(`${Const.API_DISCIPLINES}/approve/${params.disciplineId}`)
  },
  putAskApproveDiscipline (params) {
    return custAxios().put(`${Const.API_DISCIPLINES}/askApproval/${params.disciplineId}`)
  },
  putDisapproveDiscipline (params) {
    return custAxios().post(`${Const.API_DISCIPLINES}/disapprove/${params.disciplineId}`, {
      motive: params.motive
    })
  }
}
